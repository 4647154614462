import { features } from './features'; 

features.showEstadisticas = true;
features.showAnalisis = true;
features.showCargaPAC = true;
features.showTractorMarcha = true;
features.showTractorRPM = true;
features.showTractorPresion = true;
features.showTractorVelocidad = true;
features.showTractorBoquillas = true;
features.showVisitasConsultas = true;
features.showDesplazamientoObra = true;
features.showPartida = true;
features.puedeImprimir = true;
features.showAbonado = true;
features.showRegistroTratamiento = true;
features.showPhCaldo = true;
features.showEnviarWhatsapp = true;
features.showEnviarCorreo = true;
features.showEnviarWhatsappVisitas = true;
features.showEnviarCorreoVisitas = true;
features.showTareasBio = true;
features.showOrdenAplicacionProductos = true;
features.showMeteoBtn = true;
features.showRecetaGlobalGAP = true;
features.showClimatologia = true;
features.showRecetaPascualCabedo = true;
features.adminCanSeeAllRecords = true;
features.showVisitasUploadPhotos = true;
features.showMapasSatelite = false;
features.showAnalisisSectorParcelas = true;
features.showTareasSiembra = false;
features.showTareasOtros = true;
features.showRopoRomaRegepa = true;
features.showConsultaRopo = true;
features.showClientesRegepa = true;
features.showStock = true;
features.showStockTipoDeDato = true;
features.showStockProveedor = true;
features.showStockEnvase = false;
features.showReto = false;
features.showVentusky = true;
features.showMapaTratamientos = true; //NEW
features.showFotoTrajador = true;
features.showVentasComercioProveedor = true;
features.showStockProveedorClienteDropdown = false;
features.showMapaGraniot = true;

features.showMapaLabels = true;

features.showMotivo = false;
features.showVisitasVariedades = true;
features.hiddenVisitasCerezo = true;
features.hiddenVisitasContador = true;
features.showVisitasFormAbonosFolques = true;
features.showVisitaCarenciasMultiple = true;
features.showVisitasCamposPersonalizados = true;

features.showUsuariosLogs = true;

features.activateSIEX = false;

features.showCostes = true;
features.applicationType = 'fitosgest';

export const environment = {
    production: true,
    v: '2',
    serverUrl: 'https://lluchychulbi.fitosgest.com/',
    logoUrl: 'assets/logo_lluchychulbi.png',
    appName: 'lluchychulbi',
    features: features
};