<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
        [request]="getRequest"
        [cols]="cols"
        [filtering]="filter"
        [actionButtonText]="canAddFincas ? 'Añadir ' + fincaName : ''"
        (actionButtonClick)="add()"
        [globalConditionalStyle]="globalConditionalStyle"
        [globalVisualTransform]="globalVisualTransform"
        [customExportCsv]="showFincasCsv">

        <ng-template #header>
            <div style="margin-left: 20px;">
                 <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white">
                    <i class="fa fa-download"></i>
                    Exportar listado
                </button>
            </div>
        </ng-template>
        <ng-template #thEnd>
            <th *ngIf="showCuadernoDeCampo">
                <p class="d-flex justify-content-center" style="margin-bottom: 0">{{appName === 'donjardin' ? 'Registro Transacciones' : 'Cuaderno'}}</p>
            </th>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
            <th *ngIf="showCargaPAC">
                <p class="d-flex justify-content-center" style="margin-bottom: 0">PAC</p>
            </th>
            <th *ngIf="showFincasGenGml">
                <p class="d-flex justify-content-center" style="margin-bottom: 0">GML</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td *ngIf="showCuadernoDeCampo">
                <div class="d-flex justify-content-center">
                    <div class="justify-content-center">
                        <button (click)="show('generate', data); filterSectores()" class="d-flex justify-content-center align-items-center" style="
                            height: 20px;
                            width: 85px;
                            border: none;
                            border-radius: 3px;
                            background-color: #345fdc;
                            color: white;
                            cursor: pointer;">
                            <i class="fa fa-book"></i>
                            <span>&nbsp;Generar</span>
                        </button>
                    </div>
                </div>
            </td>
            <td>
                <app-action-buttons
                    [hideEdit]="!canEditFincas"
                    [hideDelete]="!canDeleteFincas" 
                    [showDuplicate] = "canDuplicateFinca"               
                    (duplicateRowButtonEvent)="duplicate(data.data)"
                    (editRowButtonEvent)="edit(data.data)"
                    (deleteRowButtonEvent)="show('delete', data.data)"
                    (showRowButtonEvent)="show('info', data.data)">
                </app-action-buttons>
            </td>
            <td *ngIf="showCargaPAC">
                <div class="d-flex justify-content-center">
                    <div class="justify-content-center">
                        <button class="d-flex justify-content-center align-items-center" (click)="uploadDialog(data.data)" style="
                            height: 20px;
                            width: 45px;
                            border-radius: 3px;
                            background-color: #28a645;
                            color: white">
                            <i class="fa fa-upload"></i>
                        </button>
                    </div>
                </div>

            </td>
            <td *ngIf="showFincasGenGml">
                <div class="d-flex justify-content-center">
                    <div class="justify-content-center">
                        <button class="d-flex justify-content-center align-items-center" (click)="generaGML(data.data)"  style="
                            height: 20px;
                            width: 45px;
                            border-radius: 3px;
                            background-color: #2863a6;
                            color: white">
                            <i class="fa fa-download"></i>
                        </button>
                    </div>
                </div>

            </td>
        </ng-template>
    </app-common-list-table>
</div>

<p-dialog 
    *ngIf="canShow['generate']"
    header="Generar Word" 
    [(visible)]="canShow['generate']" 
    (onShow)="scrollToTop()"
>

    <div [ngStyle]="{'width.vw': '50'}" style="min-height: 40vw !important; ">
        <app-form-builder
            [model]="model"
            [fields]="generarFields"
            [distributionColumns]="2"
            (formChanges)="formChanges()">
        </app-form-builder>
        <div *ngIf="parcelasAux?.length === 0 && sectores.selected">
            <br>
            <p style="text-align: center; color: red">Este sector no tiene parcelas</p>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('generate')">Cerrar</button>

            <button *ngIf="appName !== 'donjardin'" class="d-flex justify-content-center mx-2 btn btn-primary" (click)="generarCuaderno();">Generar Word</button>


            <button *ngIf="showFincasInformeTratamientos || (user?.nombre === 'ALEJANDRO' && appName === 'melia')" class="d-flex justify-content-center mx-3" (click)="generarCuaderno(true, false);">
                <button class="btn btn-secondary">{{showTareasMto ? 'Generar' : 'Informe Tratamientos'}}</button>
            </button>

            <button *ngIf="showFincasLibroFertilizantes" class="d-flex justify-content-center mx-3" (click)="generarCuaderno(false, true);">
                <button class="btn btn-secondary">Generar Libro Fertilizantes</button>
            </button>
            <a *ngIf="this.applicationType === 'cropgest' && user?.tipo === 'comunero'" class="d-flex justify-content-center mx-3">
                <button class="btn btn-secondary">Enviar a SIEX</button>
            </a>
        </div>
        <br>
    </div>
</p-dialog>

<p-dialog 
    *ngIf="canShow['info']"
    header="Detalle del registro" 
    [(visible)]="canShow['info']" 
    (onShow)="scrollToTop()"
>

    <div style="width: 80vw !important">
        <h5 *ngIf="this.appName !== 'gardengest'">Datos  {{appName === 'gardengest' ? 'del' : 'de la' }}  {{ fincaName }}</h5>
        <app-form-builder [fields]="formFields" [distributionColumns]="3" [model]="model">
        </app-form-builder>
        <br>
        <h5 *ngIf="this.appName !== 'gardengest'">Datos del titular</h5>
        <app-form-builder *ngIf="this.appName !== 'gardengest'"
        [fields]="formFieldsTitular" 
        [distributionColumns]="3" 
        [model]="model"
        >
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>
</p-dialog>

<p-dialog 
    *ngIf="canShow['delete']"
    header="Eliminar registro" 
    [(visible)]="canShow['delete']" 
    (onShow)="scrollToTop()"
>

    <div style="width: 80vw !important">
        <h5 *ngIf="this.appName !== 'gardengest'">Datos  {{appName === 'gardengest' ? 'del' : 'de la' }}  {{ fincaName }}</h5>
        <app-form-builder [fields]="formFields" [distributionColumns]="3" [model]="model">
        </app-form-builder>
        <br>
        <h5 *ngIf="this.appName !== 'gardengest'">Datos del titular</h5>
        <app-form-builder *ngIf="this.appName !== 'gardengest'"
        [fields]="formFieldsTitular" 
        [distributionColumns]="3" 
        [model]="model"
        >
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button [warning]="deleteWarning" (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading && deleteWarning" style="color: red; text-align: center">
            {{deleteWarningMessage}}
        </p>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading" style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>

<!-- SUBIR/IMPORTAR ARCHIVO PAC -->
<p-dialog width="600" header="Importar datos PAC" [closeOnEscape]="false" [closable]="false" [(visible)]="showModalPAC" (onShow)="scrollToTop()">

    <div *ngIf="showModalPAC" style="width: 36vw !important">

        <div>
            <p-fileUpload name="myfile[]" [disabled]="!enableUpload" multiple="false" [showCancelButton]="false" chooseLabel="Seleccionar archivo" uploadLabel="Enviar" [url]="urlUpload" (onSelect)="checkFile($event)" (onUpload)="uploaded($event)">
            </p-fileUpload>
        </div>

        <div *ngIf="showPACError">
            <br>
            <p style="max-width: 400px; text-align: center; color: red" [innerHTML]="txtPACError"></p>
            <p style="max-width: 400px; text-align: center; color: green" [innerHTML]="txtPACInfo"></p>

        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="closeFUDialog();">Cerrar</button>
        </div>
        <br>
    </div>
</p-dialog>