import {Component, HostBinding, HostListener, isDevMode} from '@angular/core';
import {environment} from '../environments/environment';
import { StorageManager } from './common/storage-manager.class';
import { MessageService } from 'primeng/api';
import { LogUpdateService } from './service/ServiceWorker/log-update.service';
import { CheckForUpdateService } from './service/ServiceWorker/check-for-update.service';
import { HandleUnrecoverableStateService } from './service/ServiceWorker/handle-unrecoverable-state.service';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';
import { PrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';

import type { OnInit } from '@angular/core';
import type { VersionReadyEvent } from '@angular/service-worker';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    @HostBinding('style.--color_active') color_active = 'white';

    public title = (environment.appName || '').toUpperCase();
    public serverUrl = environment.serverUrl;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public networkStatus = false;
    
    constructor(
        private config: PrimeNG,
        private updates: SwUpdate,
        _log: LogUpdateService, _check: CheckForUpdateService, _handle: HandleUnrecoverableStateService,
        private messageService: MessageService,
    ) {
        // Default theme configuration
        this.config.theme.set(
            {
            preset: Aura,
            options: {
                prefix: 'p',
                // darkModeSelector: 'system',
                darkModeSelector: 'light',
                cssLayer: false
            }
        });
    }

    @HostBinding('style.--color_primary')
    get color_primary() {
        return environment.features.applicationType === 'fitosgest' ? '#2783c6' : 
            environment.features.applicationType === 'cropgest' ? '#001F5B' :
                environment.features.applicationType === 'gardengest' ? '#c39a6b' :
                    environment.features.applicationType === 'agrogest' ? '#FFA63B' :
                        environment.features.applicationType === 'purple' ? '#a349a4' :
                            '#e21f4c';
    }
  
    @HostBinding('style.--color_secondary')
    get color_secondary() {
        return environment.features.applicationType === 'fitosgest' ? '#4664a8' : 
            environment.features.applicationType === 'cropgest' ? 'rgb(4, 18, 46)' :
                environment.features.applicationType === 'gardengest' ? '#d39a6b' :
                    environment.features.applicationType === 'agrogest' ? '#e28824' :
                        environment.features.applicationType === 'purple' ? '#681a69' :
                            '#d61b47';
    }
    @HostListener('window:offline', ['$event'])
    offlineEvent(event: {type: string}) {
        this.processOfflineEvent(event);
    }

    @HostListener('window:online', ['$event'])
    onlineEvent(event: {type: string}) {
        this.processOnline(event);
    }
    processOnline(event: { type: string; }){
        if (event.type === 'online') {
            this.messageService.add({ 
                severity: 'success', 
                summary: 'Conexión a Internet Recuperada', 
                detail: 'Refresca el navegador para ver los datos actuales.' 
            });
        }
    }

    processOfflineEvent(event: { type: string; }){
        if (event.type === 'offline') {
            this.messageService.add({ 
                severity: 'warn', 
                summary: 'Sin Conexión a Internet', 
                detail: `Acabas de perder la conexión a Internet: 
                los datos visibles son a los que tenias acceso antes de perder la conexión.` 
            });
        }
    }
    
    ngOnInit() {
        this.updates.versionUpdates
            .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
            .subscribe(() => {
                this.messageService.add({ 
                    severity: 'secondary', 
                    summary: 'Nueva Versión Disponible', 
                    detail: 'Una nueva versión de la app se encuentra disponible: pulsa F5 para acceder a ella.' 
                });
            });
        this.config.setTranslation({
            // Españolización del Calendario
            monthNames:	[
                'Enero', 
                'Febrero', 
                'Marzo', 
                'Abril', 
                'Mayo', 
                'Junio', 
                'Julio', 
                'Agosto', 
                'Septiembre', 
                'Octubre', 
                'Noviembre', 
                'Diciembre'
            ],
            monthNamesShort: ['En', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lu', 'Mar', 'Mier', 'Juev', 'Vier', 'Sab'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S']
        });
        
        if (this.title === 'MELIA') {
            this.title = 'FITOGEST BASIC';
        }
        document.title = this.title;


        if (this.applicationType === 'viverogest') {
            environment.features.hasPlantas = true;
        }
        
        if ((this.applicationType === 'viverogest' || this.applicationType === 'agrogest' || this.applicationType === 'cropgest')
             && this.appName !== 'benibal') {
            environment.features.showDesplazamientoObra = false;
            environment.features.showConsultaRopo = false;
            environment.features.mostrarTodosTratamientos = true;
            environment.features.showTratamientosAllProductos = true;
        }
        
        if (StorageManager.getUser()?.tipo === 'comunero') {
            environment.features.showUsuariosFirma = false;
            environment.features.showCostes = true;
            environment.features.userCanSeeCostes = true;
            environment.features.showStock = true;
        }

        if (StorageManager.getUser()?.rol === 'responsable' && this.appName !== 'benibal') {
            environment.features.showCargaPAC = false;
            environment.features.canAddClients = false;
            environment.features.canAddFincas = false;
            environment.features.canDeleteFincas = false;
            environment.features.canAddSectores = false;
            environment.features.canDeleteSectores = false;
            environment.features.canAddParcelas = false;
            environment.features.canDeleteParcelas = false;
            environment.features.showTareasFitosanitariasCampaign = true;
            environment.features.showTareasFertilizantesCampaign = true;
            environment.features.showTareasOtrasCampaign = true;
            environment.features.showStock = true;
        }
        
        //MES QUE JARDINS - VERSION GARDENGEST
        /*
        Este hace uso de la version general de gardengest
        */

        if (environment.appName === 'gardengest' && StorageManager.getUser().id === '4'){
            environment.features.showProductosFeritilizantes = false;
            environment.features.showTareasFertilizantes = false;
            environment.features.showAnalisis = false;
            environment.features.showCuadernoDeCampo = false;
            environment.features.showCargaPAC = false;
            environment.features.showMapas = false;
            environment.features.showHistoricoFT = false;
            environment.features.showEnviarWhatsapp = false;
            environment.features.showTareasFertilizantes = false;
        }
        
        if (environment.appName === 'agrosalvi') {
            
            if (StorageManager.getUser()?.tipo === 'Garden') {
                environment.features.fincaName = 'Dirección';
                environment.features.fincasName = 'Direcciones';
                environment.features.parcelaName = 'Unidad';
                environment.features.parcelasName = 'Unidades';
                environment.features.showMostrarTodosClientes = true;
            } else if (StorageManager.getUser().tipo === 'Agro') {
                environment.features.showMostrarTodosClientes = true;
            }

        } 

        if (environment.features.showCostes) {
            
            environment.features.precioHora = true;
            environment.features.showTrabajadoresCargos = true;
            environment.features.showCostesOtros = true;
            environment.features.showCostesProductos = true;
            environment.features.showCostesTareasFertiCsv = true;
            environment.features.showCostesTareasOtrasCsv = true;
            environment.features.showFormatHourMinute = true;
            environment.features.showTareasFitoHoras = true;
            environment.features.showTareasOtrosDuracion = true;
            environment.features.timeStartStopTareas = true;
            environment.features.tareasHorasTotales = true;
            environment.features.horaFechaFinActualPorDefecto = true;
            environment.features.showTareasFitoLitrosAplicados = true;
            environment.features.showTrabajadoresFertilizantes = true;
            environment.features.showStockAdditionalFields = true;
            
        }

        if (StorageManager.getUser()?.rol === 'basico' && environment.appName === 'sirga') {
            environment.features.hasFincas = false;
            environment.features.showMapas = false;
            environment.features.showVentas = false;
            environment.features.showVademecumMenu = false;
            environment.features.hasProductos = false;
            environment.features.showVisitas = false;
            environment.features.showCostes = false;
            environment.features.showStock = false;
        }

        
        if (StorageManager.getUser()?.rol === 'aplicador' ) {
            
            environment.features.hasFincas = false;
            environment.features.showMapas = false;
            environment.features.showVentas = false;
            environment.features.showVademecumMenu = false;
            environment.features.hasProductos = false;
            environment.features.showVisitas = false;
            environment.features.showCostes = false;
            environment.features.showStock = false;
            environment.features.showAplicadoresMenu = false;
            
            if (environment.appName === 'ava') {
                environment.features.showMapas = true;
                environment.features.showMapaParcelas = false;
                environment.features.showVentusky = false;
                environment.features.showMapaTratamientos = true;
            }
        }

        if (StorageManager.getUser()?.rol === 'basico' && environment.appName === 'sirga') {
            environment.features.showTratamientosMenu = false;
            environment.features.showTareasFitosanitarias = false;
            environment.features.showTareasFertilizantes = false;
            
            window.location.href = this.serverUrl + '#/dashboard/fichajes';
        }

        if (StorageManager.getUser()?.rol === 'user' && environment.appName === 'servalbert') {
            environment.features.showDesplazamientoObra = false;
            environment.features.showVentas = false;
        }


        setTimeout(() => {
            if (isDevMode()){
                const favIcon: HTMLLinkElement = document.querySelector('#appIcon') ?? new HTMLLinkElement();
                favIcon.href = 'https://locatec.es/favicon.ico';
                this.messageService.add({ 
                    severity: 'contrast', 
                    summary: 'Estás en Localhost', 
                    detail: 'Tu amigable recordatorio de que estás viendo los cambios que haces en local.' 
                });
            }
        }, 500);
    }

    reload(){
        location.reload();
        this.messageService.clear();
    }
}
