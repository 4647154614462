<br>

<!-- Ejecucion creacion parcelas de recintos  -->
<!--  <div>
  <button class="btn btn-primary" (click)="conversionEPSG();">EPSG</button>
</div>   -->

<div>
  <app-common-list-table
          [request]="getRequest"
          [cols]="cols"
          [globalConditionalStyle]="globalConditionalStyle"
          [globalVisualTransform]="globalVisualTransform"
          [filtering]="filter"
          [actionButtonText]="canAddParcelas ? btnAnadir : ''"
          (actionButtonClick)="add()"
          (emitSearchText)="updateFooter()"
          [customExportCsv]="showBotonPrintParcelas">

          <ng-template #tdEnd let-data>
            <td *ngIf="this.appName === 'agrosalvi'">
              <div class="col col-md-1 col-offset-1">
                <button class="btn btn-warning" style="margin-left:0px !important" (click)="abreMapa(data);">
                <i class="fa fa-search" aria-hidden="true"></i>
                <span> Mapa</span>
                </button>
            </div>
            </td>
            <td>
                <div class="d-flex justify-content-center align-items-center">
                    <app-action-buttons [showDuplicate]="this.rol !== 'responsable'" (duplicateRowButtonEvent)="duplicate(data)" [hideEdit]="!canEditParcelas"
                    [hideDelete]="!canDeleteParcelas"   
                        (editRowButtonEvent)="edit(data.data)" (deleteRowButtonEvent)="show('delete', data.data)" (showRowButtonEvent)="show('info', data.data)">
                    </app-action-buttons> 

                </div>
            </td>
        </ng-template>          

    <ng-template #header>
      <div style="display: flex;">
        <div style="margin-left: 20px;">
          <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem;  background-color: #16A2B7; color: white">
              <i class="fa fa-download"></i>
              Exportar listado
          </button>
        </div>
      </div>
      <div style="display: flex;">
        <div>
          <div class="small px-3">
            Superficie total (SigPAC): <b>{{totalSupSigpac || 0}} {{textoSuperficie}}</b>
            <br>
            Superficie total (cultivada): <b>{{totalSupCultivada || 0}} {{textoSuperficie}}</b>
          </div>
        </div>
        <div *ngIf="showCampaign" id="campaignAno" style="width: 15rem !important;" class="d-flex justify-content-center">
          <label for="">Año</label>
          <p-select
            placeholder="{{selectedYears}}"
            [options]="years"
            [(ngModel)]="selectedYears"
            (onChange)="selectedCampaign($event.value)">
          </p-select>
        </div>
        
<!--         <div *ngIf="showBotonPrintParcelas" class="mr-2">
          <button tooltipPosition="top" pTooltip="Imprimir" class="btn btn-secondary"
              style="font-size: .7em;" (click)="showImprimir()" >
              <i class="fa fa-print"></i>
          </button>
      </div>   -->      

    </div>       
      



      


    </ng-template>

    <ng-template #thEnd>
      <th *ngIf="this.appName === 'agrosalvi'">
        <p class="d-flex justify-content-center" style="margin-bottom: 0">Mapa</p>
      </th>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
                (editRowButtonEvent)="edit(data)"
                (deleteRowButtonEvent)="show('delete', data)"
                (showRowButtonEvent)="show('info', data)">
        </app-action-buttons>
      </td>
    </ng-template>

    <ng-template #footer let-col>
      <div style="font-size: 0.75em; text-align: left" *ngIf="(['finca'].includes(col.field))">
        TOTAL:
      </div>
      <div *ngIf="(['superficie_sigpac', 'superficie_cultivada'].includes(col.field))"
        style="font-size: 0.75em; text-align: right; font-weight: bold; padding-right: 34px">
        {{col?.footer  || 0}}
      </div>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  *ngIf="canShow['info']"
  header="Detalle del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()"
>

  <div style="width: 80vw !important">
    <h4>Datos {{parcelaName}}</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [distributionOrder]="hasPlantas ? [2, 4, 4, 2] : [4]"
            [fields]="formFieldsDatosParcela">
    </app-form-builder>
    <br>
    <br>
    <h4>Datos SigPAC</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosSigpac">
    </app-form-builder>
    <br>
    <br>
    <h4>Datos generales</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosGenerales">
    </app-form-builder>
    <br>
    <div *ngIf="showDatosMedioambientales">
      <h4>Puntos de captación de agua</h4>
      <app-form-builder
          [model]="model"
          [distributionColumns]="4"
          [fields]="formFieldsDatosMedioambientales">
      </app-form-builder>
      <br>

      <h4>Parcelas en zonas específicas</h4>
      <app-form-builder
          [model]="model"
          [distributionColumns]="4"
          [fields]="formFieldsZonasEspecificas">
      </app-form-builder>
      <br>
    </div>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
  *ngIf="canShow['delete']"
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()"
>

  <div style="width: 80vw !important">
    <h4>Datos Parcela</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosParcela">
    </app-form-builder>
    <br>
    <br>
    <h4>Datos SigPAC</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosSigpac">
    </app-form-builder>
    <br>
    <br>
    <h4>Datos generales</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosGenerales">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>



<p-dialog [header]="'Mapa ' + parcelaName" [(visible)]="mapaAbierto" #mapaDialog (onShow)="scrollToTop()">
                         
  <div *ngIf="mapaAbierto" style="height:86%; margin-left:20px; margin-right:20px; width: 80vw !important">
    <app-mapa-add
        [origin]="origin"
        [finca]="finca"
        [localidad]="localidad"
        [cultivo]="cultivo"
        [variedad]="variedad"
        [parcelaNombre]="parcelaNombre"
        [coordenadas]="coordenadas"
        (fromMapa)="getFromMapa($event)">
    </app-mapa-add>
  </div>
  <br>

</p-dialog>