
import { Filtering } from './../../../../service/filtering/filtering';
import {environment} from './../../../../../environments/environment';
import {Component} from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Router} from '@angular/router';
import {FincasApiService} from '../../../../service/api/fincas-api.service';
import {StorageManager} from '../../../../common/storage-manager.class';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {Utils} from '../../../../common/utils';
import {BaseView} from '../../../base-view';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { createCsv } from 'src/app/common/createCsv';
import { firstValueFrom } from 'rxjs';

import type { OnInit, OnDestroy, AfterViewInit  } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { FullMapModel } from 'src/app/models/form-common/full-map.model';
import { TreeNode } from 'primeng/api';


declare const convierteEPSG: (coordenadas: string[]) => string;

const TRUTHY_VALUES = [1, '1', true, 'true'];

// interface TreeNodeData<T> {
//     data: T; // Donde T es el tipo del modelo de tus datos
//     children?: TreeNodeData<T>[]; // Si hay nodos hijos, son del mismo tipo
//     leaf?: boolean; // Indicador de si es un nodo hoja
//     activo?: boolean;
//     codigo_observaciones_forestales?: string;
//     codigo_secano_regadio?: string;
//     codigo_sistema_cultivo?: string;
//     codigo_sistema_riego?: string;
//     sistema_asesoramiento?: string;
// }

@Component({
    selector: 'app-parcelas',
    templateUrl: './parcelas.component.html',
    styleUrls: ['./parcelas.component.scss']
})
export class ParcelasComponent extends BaseView<ParcelasModel> implements OnInit, AfterViewInit, OnDestroy {

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public rol: string = StorageManager.getUser().rol;
    public activateApiSIEX = environment.features.activateSIEX;
    public showBotonPrintParcelas = environment.features.showBotonPrintParcelas;
    public showParcelasFincasCol = environment.features.showParcelasFincasCol;
    public showParcelasSector = environment.features.showParcelasSector;
    public showNumeroFincas = environment.features.showNumeroFincas;
    public showNombreParcela = environment.features.showNombreParcela;
    public showSectores = environment.features.showSectores;
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public canAddParcelas = environment.features.canAddParcelas;
    public canEditParcelas = environment.features.canEditParcelas;
    public canDeleteParcelas = environment.features.canDeleteParcelas;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public showDatosMedioambientales = environment.features.showDatosMedioambientales;
    public showParcelasEstacion = environment.features.showParcelasEstacion;
    public showParcelasProvinciaCol = environment.features.showParcelasProvinciaCol;
    public showParcelasMunicipioCol = environment.features.showParcelasMunicipioCol;
    public showParcelasCodigoTrazabilidad = environment.features.showParcelasCodigoTrazabilidad;
    public showCampaign = environment.features.showTareasFertilizantesCampaign;
    public showParcelasTurbo = environment.features.showParcelasTurbo;  
    public textoSuperficie = environment.features.textoSuperficie; 
    public hasPlantas = environment.features.hasPlantas; 
    public years = [
        {label: '...', value: ''},
        {label: '2020', value: '_2020_'},
        {label: '2021', value: '_2021_'},
        {label: '2022', value: '_2022_'},
        {label: '2023', value: '_2023_'},
        {label: '2024', value: '_2024_'},
        {label: '2025', value: '_2025_'},
        {label: '2026', value: '_2026_'},
        {label: '2027', value: '_2027_'},
        {label: '2028', value: '_2028_'},
        {label: '2029', value: '_2029_'},
        {label: '2030', value: '_2030_'},
    ];     
    public selectedYears = '';

    public btnAnadir = `Añadir ${this.parcelaName}`;

    public filter: Filtering<ParcelasModel> = new Filtering<ParcelasModel>();
    public getRequest: RequestHandler<ParcelasModel[]> = this.fincasApi.parcelasGET;
    public deleteRequest: RequestHandler<ParcelasModel> = this.fincasApi.parcelasDELETE;
    public menuItemId = 'parcelas';

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string,boolean> = {};
    public override model = {} as ParcelasModel;

    public totalSupSigpac = 0;
    public totalSupCultivada = 0;

    public clients_width_parcelas = ['covidai', 'pyf', 'almassora', 'agricar', 'jobarma', 'prodalbar', 'agrosalvi'];

    public anchoNombre = this.clients_width_parcelas.includes(this.appName) ? '220px' : '120px';

    public showAllParcelas = false;
    
    public mapaOpened = false;
    public mapaAbierto = false;
    public origin = 'parcelas';
    public finca = 'finca';
    public localidad = 'localidad';
    public cultivo = 'cultivo';
    public variedad = 'variedad';
    public parcelaNombre = 'nombre parcela';
    public coordenadas = 'coordenadas parcela';
    
    public formFieldsDatosParcela: ItemInterface<object>[] = [
        {
            field: 'finca',
            name: this.fincaName,
            label: this.fincaName,
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sector',
            name: this.sectorName,
            label: this.sectorName,
            inputType: {type: InputType.TEXT},
            visible: this.showParcelasSector && this.showSectores && this.appName !== 'bravosol'
        },
        {
            field: 'numero_parcela_agricola',
            name: 'Número de Parcela Agrícola',
            label: 'Número de Parcela Agrícola',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'agropecuariaramirogruas' || this.appName === 'prodalbar'
        },
        {
            field: 'pendiente', 
            name: 'Pendiente',
            label: '% Pendiente',
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'cultivo',
            name: this.appName === 'momparler' ? 
                'Género/Especie SIEX' : 
                environment.features.showTareasMto ? 
                    'Especie SIEX' : 
                    'Cultivo SIEX',
            label: this.appName === 'momparler' ? 
                'Género/Especie SIEX' : 
                environment.features.showTareasMto ? 
                    'Especie SIEX' : 
                    'Cultivo SIEX',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'variedad',
            name: 'Variedad SIEX',
            label: 'Variedad',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'cultivo_legacy',
            name: this.appName === 'momparler' ? 
                'Género/Especie' : 
                environment.features.showTareasMto ? 
                    'Especie' : 
                    'Cultivo',
            label: this.appName === 'momparler' ? 
                'Género/Especie' : 
                environment.features.showTareasMto ? 
                    'Especie' : 
                    'Cultivo',
            inputType: {type: InputType.TEXT},
            visible: this.hasPlantas
        },
        {
            field: 'variedad_legacy',
            name: 'Variedad',
            label: 'Variedad',
            inputType: {type: InputType.TEXT},
            visible: this.hasPlantas
        },
        {
            field: 'marco', 
            name: 'Marco Plantación (Filas x Plantas)', 
            label: this.appName === 'cartagenafresh' ? 
                'Nº Cintas Riego' : 
                'Marco Plantación (Filas x Plantas)', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showMarcoPlantacion
        },
        {
            field: 'pies',
            name: 'Pies',
            label: 'Pies',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'yagoaznar'
        },
        {
            field: 'anyo_plantacion',
            name: 'Año Plantación',
            label: this.appName === 'cartagenafresh' ? 'Semana Plantación' : 'Año Plantación',
            inputType: {type: InputType.TEXT},
            visible: environment.features.showAnyoPlantacion
        },
        {
            field: 'plantas', 
            name: 'Nº Árboles/Plantas', 
            label: this.appName === 'cartagenafresh' ? 
                'Kg/Ha' : 
                'Nº Árboles/Plantas', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showPlantas
        },
        {
            field: 'patron', 
            name: 'Patrón', 
            label: environment.appName === 'ortizlavado' ? 
                'Portainjertos' : 
                this.appName === 'cartagenafresh' ? 
                    'Cama' : 
                    'Patrón',
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showPatron
        },
        {
            field: 'aplicadores_nombre', 
            label: 'Aplicadores', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType === 'cropgest'
        },
        {
            field: 'diametro_copa', 
            name: 'Diámetro de Copa', 
            label: 'Diámetro de Copa',
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agufert'
        },
        {
            field: 'distancia_menor_50',
            name: 'Distáncia Menor a 50m',
            label: 'DISTANCIA MENOR A 50m',
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'numero_metros', 
            name: 'Número de Metros',
            label: 'Número de Metros',
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'captacion_agua', 
            name: 'Captación Agua',
            label: 'Captación Agua',
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'zonas_especificas',
            name: 'Zonas Específicas', 
            label: 'Zonas Específicas',
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'zona_vulnerable', 
            name: 'Zona Vulnerable (ha)',
            label: 'Zona Vulnerable (ha)',
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'herbaceo_lenyoso',
            name: 'Herbáceo / Leñoso',
            label: 'Herbáceo / Leñoso',
            inputType: {type: InputType.SWITCH}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'prevision_produccion', 
            name: 'Previsión Producción', 
            label: 'Previsión Producción',
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName === 'icod'
        },
        {
            field: 'codigo_trazabilidad', 
            name: 'Código de Trazabilidad',
            label: 'Código de trazabilidad',
            inputType: {type: InputType.TEXT},          
            visible: this.showParcelasCodigoTrazabilidad
        },
        {
            field: 'uds_bandeja', 
            name: 'Uds. Bandeja', 
            label: 'Uds. Bandeja', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'viverospereira'
        },
        {
            field: 'tipo_siembra', 
            name: 'Tipo de Siembra',
            label: 'Tipo de Siembra',
            inputType: {type: InputType.TEXT},          
            visible: this.appName === 'pedrosaez'
        },
        {
            field: 'regimen_tenencia', 
            label: 'Régimen tenencia',
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName === 'pyf' || this.appName === 'prodalbar'
        },        
        {
            field: 'produccion_ecologica', 
            label: 'Producción Ecológica',
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.appName === 'prodalbar'
        },
        {
            field: 'activo', 
            name: 'Activo', 
            label: 'Activo', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.showAplicadoresActivo
        },
        {
            field: 'pozo', 
            name: 'Pozo',
            label: 'Pozo',
            inputType: {type: InputType.TEXT},          
            visible: environment.appName === 'pyf'
        },
        {
            field: 'rios',
            name: 'Rios', 
            label: 'Rios',
            inputType: {type: InputType.TEXT},          
            visible: environment.appName === 'pyf'
        },
        {
            field: 'estacion_control',
            name: 'Estación de Control', 
            label: 'Estación de control',
            inputType: {type: InputType.TEXT},          
            visible: environment.appName === 'pyf'
        },
        {
            field: 'producto', 
            name: 'Producto', 
            label: 'Producto', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'bravosol'
        },
        {
            field: 'partida', 
            name: 'Partida', 
            label: 'Partida', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showParcelasPartida
        },
        {
            field: 'comercio', 
            name: 'Comercio', 
            label: 'Comercio', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showParcelasComercio || environment.features.showParcelasComercioDropdown
        },
        {
            field: 'pozos', 
            name: 'Pozos', 
            label: 'Pozos', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showParcelasPozos
        },   
        {
            field: 'turbo', 
            name: 'Turbo', 
            label: 'Turbo', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: environment.features.showParcelasPozos
        },
        {
            field: 'lote', 
            name: 'Nº lote',
            label: 'Nº lote',
            inputType: {type: InputType.EDIT_TEXT},          
            visible: environment.features.showParcelasLote
        },
        {
            label: 'Procedencia Agua/Origen', 
            field: 'procedencia_agua', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType === 'cropgest',
        },
        {
            label: 'Linde', 
            field: 'linde', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.applicationType === 'cropgest',
        },
        {
            label: 'Notas',
            field: 'notas',
            inputType: {type: InputType.TEXTAREA},
            visible: this.appName === 'agroleza'
        }
    ];

    public formFieldsDatosSigpac: ItemInterface<object>[] = [
        {
            field: 'nombre', 
            name: `Nombre de la ${this.parcelaName}`, 
            label: `Nombre de la ${this.parcelaName}`, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'uso_sigpac',
            name: 'Uso SigPAC', 
            label: 'Uso SigPAC',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'superficie_sigpac', 
            name: 'Superficie SigPAC', 
            label: 'Superficie SigPAC (' + this.textoSuperficie + ')', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'superficie_cultivada', 
            name: 'Superficie Cultivada', 
            label: 'Superficie Cultivada (' + this.textoSuperficie + ')', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'cod_provincia', 
            name: 'Cod. Provincia', 
            label: 'Cod. Provincia', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'municipio', 
            name: 'Municipio', 
            label: 'Municipio', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'poligono', 
            name: 'Polígono', 
            label: 'Polígono', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'numero', 
            name: `Nº ${this.parcelaName}`, 
            label: `Nº ${this.parcelaName}`, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'recinto', 
            name: 'Recinto', 
            label: 'Recinto', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'agregado', 
            name: 'Agregado', 
            label: 'Agregado', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'orden', 
            name: 'Zona', 
            label: 'Zona', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sigpac', 
            name: 'SigPAC', 
            label: 'SigPAC', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public formFieldsDatosGenerales: ItemInterface<ItemInterface<void>>[] = [
        {
            field: 'codigo_secano_regadio',
            name: 'Secano / Regadio',
            label: 'Secano / Regadio.',
            inputType: {type: InputType.TEXT}, 
            transform: [
                this.secanoRegadioHumanTransform.bind(this)
            ]
        },
        {
            field: 'codigo_sistema_riego', 
            name: 'Sistema de Riego',
            label: 'Sistema de Riego', 
            inputType: {type: InputType.TEXT}, 
            transform: [
                this.sistemaRiegoHumanTransform.bind(this)
            ]
        },
        {
            field: 'codigo_sistema_cultivo',
            name: 'Aire libre / Protegido',
            label: 'Aire Libre / Protegido ',
            inputType: {type: InputType.TEXT},
            transform: [
                this.sistemaCultivoHumanTransform.bind(this)
            ]
        },
        {
            field: 'codigo_observaciones_forestales',
            name: 'Observaciones de Forestales',
            label: 'Observaciones de Forestales',
            inputType: {type: InputType.TEXT},
            transform: [
                this.observacionesForestalesHumanTransform.bind(this)
            ],
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'sistema_asesoramiento',
            name: 'Sistema de Asesoramiento',
            label: 'Sistema de asesoramiento',
            inputType: {type: InputType.TEXT},
            transform: [
                this.sistemaAsesoramientoHumanTransform.bind(this)
            ]
        },
        {
            field: 'estacion',
            name: 'Estación',
            label: 'Estación',
            inputType: {type: InputType.TEXT},
            visible: this.showParcelasEstacion
        }
    ];

    public formFieldsDatosMedioambientales: ItemInterface<object>[] = [
        {
            field: 'incluido_en_parcela', 
            label: 'Incluido en la parcela', 
            inputType: {type: InputType.SWITCH}
        },
        {
            field: 'distancia', 
            label: 'Distancia (m3)', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'coordenadas_utm', 
            label: 'Coordenadas UTM', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'denominacion', 
            label: 'Denominación', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public formFieldsZonasEspecificas: ItemInterface<object>[] = [
        {
            field: 'zona_total', 
            label: 'Totalmente', 
            inputType: {type: InputType.SWITCH}
        },
        {
            field: 'zona_parcial', 
            label: 'Parcialmente', 
            inputType: {type: InputType.SWITCH}
        }
    ];

    public cols: ColInterface[] = [
        { field: 'id_finca', header: 'Nº Finca', filter: '', width: '140px', visible: this.showNumeroFincas },
        {field: 'finca', header: this.fincaName, width: '140px', filter: '', visible: this.showParcelasFincasCol},
        {field: 'sector', header: this.sectorName, width: '120px', filter: '', visible: this.showParcelasSector && this.showSectores},
        {field: 'nombre', header: 'Nombre ' + this.parcelaName, width: this.anchoNombre, filter: '', visible: this.showNombreParcela },
        {field: this.hasPlantas ? 'cultivo_legacy' : 'cultivo', header: 'Cultivo', width:'100px', filter: ''},
        {field: this.hasPlantas ? 'variedad_legacy' : 'variedad', width:'100px', header: 'Variedad', filter: ''},
        {field: 'sistema_asesoramiento', header: 'S.A', width:'100px', filter: '', visible: this.appName === 'pyf'},
        {field: 'estacion_control', header: 'E.C', width:'100px', filter: '', visible: this.appName === 'pyf'},
        {field: 'partida', header: 'Partida', width: '100px', footer: '', visible: environment.features.showParcelasPartidasCol},        
        {field: 'superficie_sigpac', header: 'Sup. SigPAC', width: '120px', footer: ''},
        {field: 'superficie_cultivada', header: 'Sup. Cultivada', width: '100px', footer: ''},
        {field: 'cod_provincia', header: 'Provincia', width: '100px', footer: '', visible: this.showParcelasProvinciaCol},
        {field: 'municipio', header: 'Municipio', width: '100px', footer: '', visible: this.showParcelasMunicipioCol},
        {field: 'poligono', header: 'Polígono', filter: '', width: '100px'},
        {field: 'numero', header: 'Nº Parcela', filter: '', width: '100px'},
        {field: 'recinto', header: 'Recinto', width: '100px', filter: ''},
        {field: 'prevision_produccion', header: 'Previsión Prod.', filter: '', visible: environment.appName === 'icod'},
        {field: 'activo', header: 'Activo', filter: '', width: '80px', visible: environment.features.showParcelasActivoCol},
        {field: 'aplicadores_nombre', header: 'Aplicadores', filter: '', visible: this.applicationType === 'cropgest'}
    ];

    public selectedId = '';
    public selectedId_finca = '';



    constructor(public fincasApi: FincasApiService,
        private dashboard: DashboardService,
        private router: Router,
        private _http: HttpClient) {
        super(dashboard, fincasApi.parcelasGET, fincasApi.parcelasDELETE);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
        this.getRequest.response((res: ParcelasModel[]) => {
            
            const total: ParcelasModel[] = Object.create(res).filter((it: ParcelasModel) => it);
            this.totalSupSigpac = 0;
            this.totalSupCultivada = 0;
            total.forEach(parcela => {
            
                if (!isNaN((parseFloat(parcela.superficie_sigpac ?? '') + this.totalSupSigpac))) {
                    this.totalSupSigpac += parseFloat(parcela.superficie_sigpac?.replace(',', '.') ?? '');
                }
               
                if (!isNaN((parseFloat(parcela.superficie_cultivada ?? '') + this.totalSupCultivada))) {
                    this.totalSupCultivada += parseFloat(parcela.superficie_cultivada?.replace(',', '.') ?? '');
                }
            });

            this.totalSupSigpac = +Utils.decimalFormat(this.totalSupSigpac, this.textoSuperficie.toLocaleUpperCase() === 'HA' ? 2 : 0);
            this.totalSupCultivada = 
                +Utils.decimalFormat(this.totalSupCultivada, this.textoSuperficie.toLocaleUpperCase() === 'HA' ? 2 : 0);


        });
        
        
        if ( this.showCampaign ) {
            this.filter.addFilter((copy: TreeNode<ParcelasModel>[] | void) => 
                (copy as TreeNode<ParcelasModel>[]).filter((row: TreeNode<ParcelasModel>) => {
                    console.log(row);
                    if ( this.selectedYears === '' ){
                        return true;
                    } else {
                        return this.compareDates(row);
                    }
                }));
        }   
        if (this.appName === 'pyf') {
            const anyoActual = new Date().getFullYear();
            this.selectedYears = anyoActual.toString();
            this.selectedCampaign(this.selectedYears);
        }     
    }

    selectedCampaign(event: string){
        if (this.appName === 'pyf' || this.appName === 'prosanzcu') {
            this.fincasApi.parcelasGET.toPromise({p3: event, p4: 'filtro'} as never).then(res => {
                this.filter.setValue(res as TreeNode<ParcelasModel>[]);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        this.filter.filter();
    }

    compareDates( row: TreeNode<ParcelasModel> ) {


        console.log(row);
        const parcela = row as ParcelasModel;
        const finca = parcela.finca;
 
        if ( finca != null && finca.includes(this.selectedYears) ){
            return true;
        } else {
            return false;
        }
    }    

    ngAfterViewInit() {
        this.fincasApi.parcelasGET.response(() => {
            this.updateFooter();
        });
    }

    public override show(modal: string, data: ParcelasModel) {
        if ( this.appName === 'bravosol' ) {
            data.superficie_cultivada = Utils.decimalFormat(data.superficie_cultivada?.toString() ?? '', 3 );
            data.superficie_sigpac = Utils.decimalFormat(data.superficie_sigpac?.toString() ?? '', 3 );
        }

        this.selectedId = data.id;
        this.selectedId_finca = data.id_finca ? data.id_finca : '';

        super.show(modal, data);


        this.model.activo = TRUTHY_VALUES.includes(data.activo ?? '');

        this.model.activo = data.activo ?? '';
        if ( this.showParcelasTurbo ) {
            this.formFieldsDatosParcela.forEach( (parcela, index) => {
                const parcelaTurbo = this.formFieldsDatosParcela[index];
                if ( parcela.field === 'turbo' && parcelaTurbo) {
                    const turbo = TRUTHY_VALUES.includes(this.model.turbo ?? '');
                    if (turbo === true) {
                        parcelaTurbo.label = 'Turbo Sí';
                    } else {
                        parcelaTurbo.label = 'Turbo No';
                    }
                } 
            });
        }
       
        if (this.showDatosMedioambientales) {
            this.model.incluido_en_parcela = TRUTHY_VALUES.includes(this.model.incluido_en_parcela ?? '');
            this.model.zona_total = TRUTHY_VALUES.includes(this.model.zona_total ?? '');
            this.model.zona_parcial = TRUTHY_VALUES.includes(this.model.zona_parcial ?? '');
        }
        // this.totalSupSigpac = Utils.decimalFormat(this.totalSupSigpac, this.textoSuperficie.toLocaleUpperCase() === 'HA' ? 2 : 0);
        // this.totalSupCultivada = Utils.decimalFormat(this.totalSupCultivada, this.textoSuperficie.toLocaleUpperCase() === 'HA' ? 2 : 0);
    }


    
    // public override show(modal: string, data: ParcelasModel): void {
    //     // Si necesitas trabajar con un TreeNodeData, haz la conversión aquí
    //     const treeNode = data as unknown as TreeNodeData<ParcelasModel>; // Conversión de tipo

    //     console.log(treeNode);
    
    //     if (this.appName === 'bravosol') {
    //         treeNode.data.superficie_cultivada = Utils.decimalFormat(treeNode.data.superficie_cultivada?.toString() ?? '', 3);
    //         treeNode.data.superficie_sigpac = Utils.decimalFormat(treeNode.data.superficie_sigpac?.toString() ?? '', 3);
    //     }
    
    //     console.log('Modal: ', modal);
    //     console.log('Data:', treeNode);
    
    //     this.selectedId = treeNode.data.id;
    //     this.selectedId_finca = treeNode.data.id_finca ? treeNode.data.id_finca : '';
    
    //     // Llama al método de la clase base con el objeto original `data`
    //     super.show(modal, treeNode.data);
    
    //     this.model.activo = TRUTHY_VALUES.includes(treeNode.data.activo ?? '');
    
    //     if (this.showParcelasTurbo) {
    //         this.formFieldsDatosParcela.forEach((parcela, index) => {
    //             const parcelaTurbo = this.formFieldsDatosParcela[index];
    //             if (parcela.field === 'turbo' && parcelaTurbo) {
    //                 const turbo = TRUTHY_VALUES.includes(this.model.turbo ?? '');
    //                 parcelaTurbo.label = turbo ? 'Turbo Sí' : 'Turbo No';
    //             }
    //         });
    //     }
    
    //     if (this.showDatosMedioambientales) {
    //         this.model.incluido_en_parcela = TRUTHY_VALUES.includes(this.model.incluido_en_parcela ?? '');
    //         this.model.zona_total = TRUTHY_VALUES.includes(this.model.zona_total ?? '');
    //         this.model.zona_parcial = TRUTHY_VALUES.includes(this.model.zona_parcial ?? '');
    //     }
    // }

    public eliminarParcelaCompleta(){
        console.log(this.selectedId, this.selectedId_finca);
        const parcelaId = this.selectedId;
        const parcelaIdFinca = this.selectedId_finca;

        if (parcelaId && parcelaIdFinca){
            const url = `${environment.serverUrl}ws/mapas/eliminaParcela.php?finca=${parcelaIdFinca}&parcela=${parcelaId}`;
    
            this._http.get(url, { responseType: 'text' })
                .subscribe(
                    res => {
                        if (!res.includes('ERROR')) {
                            alert('Parcela eliminada correctamente.');
                            this.filter.setValue(
                                (this.filter.value || []).filter(it => (it as ParcelasModel).id !== parcelaId)
                            );
                            this.updateFooter();
                        } else {
                            alert('Hubo un error eliminando la parcela.');
                        }
                    },
                    err => {
                        console.error('Error al eliminar la parcela:', err);
                        alert('Error conectando con el servidor.');
                    }
                );
        }else{
            console.log('Error al eliminar la parcela completamente');
        }

    }

    public add() {
        this.router.navigate(['dashboard', 'crear-parcela']);
    }


    public edit(data: ParcelasModel) {
        console.log(data);
        this.router.navigate(['dashboard', 'editar-parcela', data.id]);
    }

    // public edit123(data: TreeNodeData<ParcelasModel>) {
    //     console.log(data);
    //     this.router.navigate(['dashboard', 'editar-parcela', data.data.id]);
    // }

    public duplicate(data: ParcelasModel) {
        this.router.navigate(['dashboard', 'duplicar-parcela', data.id]);
    }    


    public sistemaCultivoHumanTransform(field: ItemInterface<void>, model: ItemInterface<void>) {

        model[field.field ?? ''] = [
            ['-', null],
            ['-', ''],
            ['Aire libre', '1'],
            ['Malla', '2'],
            ['Cubierta no accesible', '3'],
            ['Invernadero (cubierta accesible)', '4'],
            ['Forestales en rotación corta', '5'],
            ['Micorrizado', '6'],
            ['Acolchado (SG ACE)', '7'],
            ['Enarenado (SG ACE)', '8'],
            ['Bodega Sacos (Setas/champiñones)', '9'],
            ['Bodega Bandejas (Setas/champiñones)', '10'],
            ['Bodega Botellas (Setas/champiñones)', '11'],
            ['Bodega Otros (Setas/champiñones)', '12'],
            ['Nave Sacos (Setas/champiñones)', '13'],
            ['Nave Bandejas (Setas/champiñones)', '14'],
            ['Nave Botellas (Setas/champiñones)', '15'],
            ['Nave Otros (Setas/champiñones)', '16'],
            ['Hoyos (Viñedo Lanzarote)', '17'],
            ['Zanjas (Viñedo Lanzarote)', '18'],
            ['Invernadero (cubierta accesible)+ enarenado', '19'],
        ]
            .filter(data => model[field.field ?? ''] === data[1])
            .map(data => data[0]).toString();

        return model;
    }

    public observacionesForestalesHumanTransform(field: ItemInterface<void>, model: ItemInterface<void>) {

        model[field.field ?? ''] = [
            ['-', null],
            ['-', ''],
            ['EUCALIPTO', '225'],
            ['PAULONIA', '200'],
            ['CHOPO', '116'],
            ['SAUCE', '227'],
            ['ROBINIA', '230'],
            ['MIMBRERA', '442'],
        ]
            .filter(data => model[field.field ?? ''] === data[1])
            .map(data => data[0]).toString();

        return model;
    }

    public secanoRegadioHumanTransform(field: ItemInterface<void>, model: ItemInterface<void>) {


        model[field.field ?? ''] = [
            ['-', null],
            ['-', ''],
            ['Secano', 'S'],
            ['Regadio', 'R']
        ]
            .filter(data => model[field.field ?? ''] === data[1])
            .map(data => data[0]).toString();

        return model;

    }
    
    public sistemaRiegoHumanTransform(field: ItemInterface<void>, model: ItemInterface<void>) {


        model[field.field ?? ''] = [
            ['-', null],
            ['-', ''],
            ['Superficie o Gravedad', '1'],
            ['Aspersión fija', '2'],
            ['Aspersión móvil', '3'],
            ['Microaspersión', '4'],
            ['Nebulización', '5'],
            ['Goteo', '6'],
            ['Hidropónico a solución perdida', '7'],
            ['Hidropónico con recirculación de solución', '8'],
        ]
            .filter(data => model[field.field ?? ''] === data[1])
            .map(data => data[0]).toString();

        return model;
    }

    public sistemaAsesoramientoHumanTransform(field: ItemInterface<void>, model: ItemInterface<void>) {
        model[field.field ?? ''] = [
            ['-', null],
            ['-', ''],
            ['Agricultura Ecológica', 'AE'],
            ['Producción Integrada', 'PI'],
            ['Certificación Privada', 'CP'],
            ['Agrupación de Tratamiento Integrado en Agricultura', 'ATRIAS'],
            ['Asistida de un Asesor', 'AS'],
            ['Sin obligación de aplicar la GIP', 'NO'],
            ['Agrupación Oficial Reconocida', 'AO'],
            ['Asesor Individual', 'AI'],
            ['Guías de cultivo', 'GC']
        ]
            .filter(data => model[field.field ?? ''] === data[1])
            .map(data => data[0]).toString();

        return model;
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {

        if (( Utils.isNumber(value) && 
              (col.field !== 'sector' && col.field !== 'nombre')) || 
              col.field === 'superficie_sigpac' || col.field === 'superficie_cultivada') {
            return {
                textAlign: 'right',
                paddingRight: '60px',
            };
        } // if();

        if (col.field === 'poligono' || 
            col.field === 'numero' ||
            ['activo'].includes(col.field)) {
            return{
                textAlign: 'center',
                /* paddingLeft: '10px' */
            };
        }// if();

        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface, row: TreeNode<ParcelasModel>) {
        const parcela = row.data as ParcelasModel;
        if (Utils.isNumber(value)) {
            if (col.field === 'superficie_cultivada' || col.field === 'superficie_sigpac') {
                if (this.textoSuperficie !== 'Ha') {
                    return Utils.decimalFormat((value || '').replace(',', '.'), 2); 
                }
                return  Utils.decimalFormat((value || '').replace(',', '.'), 2).replace('.', ',');
            } else if (col.field === 'plantas') {
                return Utils.decimalFormat(value, 0);
            }
        }

        if (col.field === 'activo'){
            return ([1, true, '1'].includes(value)) ? 'Sí' : 'No';
        }

        if (col.field === 'finca' && this.applicationType === 'cropgest' && this.rol !== 'user') {
            return parcela.cliente + '-' + value;
        }
        return value;
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filter.value || []).forEach(it => {
            if (!isNaN((parseFloat((it as ParcelasModel)[field]?.toString() ?? '') + sum))) {
                sum += parseFloat((it as ParcelasModel)[field]?.toString().replace(',', '.') ?? '');
            }
        });

        this.cols.forEach(it => {
            if (it.field === field) {

                it.footer = this.textoSuperficie.toLocaleUpperCase() === 'HA' ?  
                    Utils.decimalFormat(sum + '', 2) : 
                    Utils.decimalFormat(sum + '', 0)  ;
            }
        });
    }

    public override updateFooter() {
        ['superficie_sigpac', 'superficie_cultivada'].forEach(it => this.sum(it));
    }

    nuevoRecinto(recinto: {id: string; convertido: number; coordenadas: string}){
        let coordenadasOK = '';
        let puntoYcoma = '';
        let coordenadas: string = recinto.coordenadas;
        coordenadas = coordenadas.replace(/\(/g, '[');
        coordenadas = coordenadas.replace(/\)/g, ']');
        const coordinates = coordenadas.split(';');
           
        coordinates.map( coor => {
            const sinComa = coor.split(',');
            if(sinComa[0] && sinComa[1]) {
                const unaCoordenada = [  
                    (sinComa[0] as string).substring(1, sinComa[0].length) , (sinComa[1] as string).substring(0, sinComa[1].length - 1) 
                ];
                const nuevasCoor = convierteEPSG(unaCoordenada);
                coordenadasOK += puntoYcoma + '(' + nuevasCoor[1] + ',' + nuevasCoor[0] + ')';
                puntoYcoma = ';';
            }
        }); 
        recinto.coordenadas = coordenadasOK;
        return recinto;
    }

    public abreMapa(
        data: { 
            finca: string; 
            localidad: string; 
            cultivo: string; 
            variedad: string; 
            nombre: string; 
            mapa: { coordenadas: string; }[]; 
        }){
        this.mapaOpened = true;
        this.mapaAbierto = !this.mapaAbierto;
        this.finca = data.finca ?? '';
        this.localidad = data.localidad ?? '';
        this.cultivo = data.cultivo ?? '';
        this.variedad = data.variedad ?? '';
        this.parcelaNombre = data.nombre ?? '';
        this.coordenadas = data.mapa[0]?.coordenadas ?? '';
    }

    public async conversionEPSG(){

        /*         this._http.get(environment.serverUrl+'ws/index.php?p1=generic&p2=recintos').subscribe(recintos =>{
            console.log({recintos});
            
        }) */
    
        const recintos = await firstValueFrom(
            this._http.get<{data: { id: string; convertido: number; coordenadas: string}[]}>
            (environment.serverUrl + 'ws/index.php?p1=generic&p2=recintosb'));


        recintos.data.filter((recinto: { id: string; convertido: number; coordenadas: string}) => 
            recinto.convertido = 0).map( (recinto: { id: string; convertido: number; coordenadas: string}) => {

            recinto = this.nuevoRecinto(recinto);
            recinto.convertido = 1;
            const body = {
                coordenadas: recinto.coordenadas,
                convertido: 1
            };

            const headers = new HttpHeaders();
            headers.append('Content-Type:', 'application/json');
            headers.append('Request-Method', 'PUT');              

            firstValueFrom(this._http.put(
                environment.serverUrl + 'ws/index.php?p1=generic&p2=recintosb&p3=' + recinto.id, 
                JSON.stringify(body), 
                {headers: headers}
            ));


        }  );
            
            
        return;
        
        
        //convierteEPSG([-672280.535474339, 4616629.0602604905]);
    }

    public changeShowAll(){
        if (this.showAllParcelas) {
            this.fincasApi.parcelasGET.toPromise({p3: 1} as never).then(res => {
                this.filtering?.setValue(res as TreeNode<ParcelasModel>[]);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        } else {
            this.fincasApi.parcelasGET.toPromise({p3: 0} as never).then(res => {
                this.filtering?.setValue(res as TreeNode<ParcelasModel>[]);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        this.filtering?.filter();
    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFieldsDatosParcela,
            this.formFieldsDatosSigpac,
            this.formFieldsDatosGenerales
        ];
      
        
        const csvContent = createCsv(arrayFormFields, this.filter.value, 'estacion' );
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Parcelas.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    }// ();

    public getFromMapa(datos: string) {
        const data = JSON.parse(datos);

        if (this.appName !== 'amoros') {
            this.model.cod_provincia = data.provincia;
            this.model.municipio = data.municipio;
            this.model.poligono = data.poligono;
            this.model.numero = data.parcela;
            this.model.recinto = data.recinto;
            this.model.superficie_sigpac = data.ha;
            this.model.sigpac = data.refpar;
            this.model.uso_sigpac = data.usoSigpac;
        }

        if (!this.model.mapa) {
            this.model.mapa = [{} as FullMapModel];
        }

        if (this.model.mapa[0]) {
            this.model.mapa[0].id_finca = this.model.id_finca ?? '';
            this.model.mapa[0].id_sector = this.model.id_sector ?? '';
            this.model.mapa[0].id_cultivo = (this.activateApiSIEX ? this.model.id_cultivo_legacy : this.model.id_cultivo) ?? '';
            this.model.mapa[0].id_usuario = StorageManager.getUser().id;
            this.model.mapa[0].coordenadas = data.coord;
            this.model.mapa[0].color = data.color;
    
            if (this.parcelaNombre !== '' && this.parcelaNombre != null) {
                this.model.nombre = this.parcelaNombre;
            } else {
                this.model.nombre = `${this.parcelaName} ${data.parcela}`;
            }
    
        }
        this.mapaAbierto = false;
    }
}